<template>
  <div>
    <h1>Admin Panel</h1>
    <table>
      <thead>
        <tr>
          <th>Email</th>
          <th>Role</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.email }}</td>
          <td>
            <select v-model="user.role" @change="updateUserRole(user)">
              <option value="user">User</option>
              <option value="editor">Editor</option>
              <option value="noob">Noob</option>
              <option value="woman">Woman</option>
              <option value="admin">Admin</option>
            </select>
          </td>
          <td>
            <button @click="editUser(user)">Edit</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { db } from "../firebase"; // Adjust the path to your firebase configuration
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";

export default {
  setup() {
    const users = ref([]);

    const loadUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      users.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    };

    const updateUserRole = async (user) => {
      const userDoc = doc(db, "users", user.id);
      await updateDoc(userDoc, { role: user.role });
    };

    const editUser = (user) => {
      console.log("Editing user:", user);
    };

    onMounted(() => {
      loadUsers();
    });

    return { users, updateUserRole, editUser };
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #757575;
  padding: 8px;
}

th {
  background-color: #505050;
  text-align: left;
}

select {
  padding: 4px;
}

button {
  margin-right: 5px;
}
</style>