<template>
  <div v-if="user || sharedUserId">
    <h1>Shopping list</h1>
    <ul>
      <li v-for="(item) in items" :key="item.id">
        <input type="checkbox" v-model="item.bought" @change="toggleBought(item)" />
        <span v-if="!item.editing" :class="{ bought: item.bought }" @click="editItem(item)">{{ item.name }}</span>
        <input v-else v-model="item.name" @blur="saveItem(item)" @keyup.enter="saveItem(item)" />
        <div>
          <button @click="remove(item.id)" type="button">Remove</button>
        </div>
      </li>
      <p v-if="items.length === 0">No items to buy</p>
    </ul>

    <div v-if="user">
      <input v-model="itemName" type="text" placeholder="Item" />
      <button @click="add" type="button">Add</button>
    </div>

    <div v-if="user">
      <button @click="shareList" type="button">Share List</button>
      <p v-if="shareLink">Share this link: <a :href="shareLink">{{ shareLink }}</a></p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { db } from "../firebase"; 
import { collection, addDoc, deleteDoc, doc, updateDoc, onSnapshot } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useRoute } from "vue-router";

export default {
  props: ["user"],
  setup() {
    const items = ref([]);
    const itemName = ref("");
    const shareLink = ref("");
    const auth = getAuth();
    const route = useRoute();
    const sharedUserId = route.query.userId;
    const user = auth.currentUser;
    const userId = user ? user.uid : sharedUserId;
    const itemsCollection = collection(db, `shoppingLists/${userId}/items`);
    let unsubscribe = null;

    const loadItems = () => {
      unsubscribe = onSnapshot(itemsCollection, (snapshot) => {
        items.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), editing: false }));
      });
    };

    const add = async () => {
      if (itemName.value.trim()) {
        await addDoc(itemsCollection, { name: itemName.value, bought: false });
        itemName.value = "";
      }
    };

    const remove = async (id) => {
      const itemDoc = doc(itemsCollection, id);
      await deleteDoc(itemDoc);
    };

    const toggleBought = async (item) => {
      const itemDoc = doc(itemsCollection, item.id);
      await updateDoc(itemDoc, { bought: item.bought });
    };

    const editItem = (item) => {
      item.editing = true;
    };

    const saveItem = async (item) => {
      item.editing = false;
      const itemDoc = doc(itemsCollection, item.id);
      await updateDoc(itemDoc, { name: item.name });
    };

    const shareList = () => {
      shareLink.value = `${window.location.origin}/shopping?userId=${userId}`;
    };

    onMounted(() => {
      loadItems();
    });

    onUnmounted(() => {
      if (unsubscribe) {
        unsubscribe();
      }
    });

    return { items, itemName, add, remove, toggleBought, editItem, saveItem, shareList, shareLink };
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.bought {
  text-decoration: line-through;
}

button {
  padding: 0.5rem 1rem;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

input[type="text"] {
  padding: 0.5rem;
  font-size: 1rem;
  margin-right: 1rem;
}
</style>