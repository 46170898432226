import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

app.use(router).mount("#app");
