import { createRouter, createWebHistory } from "vue-router";
import CounterComponent from "./components/CounterComponent.vue";
import LoginComponent from "./components/LoginComponent.vue";
import ShoppingComponent from "./components/ShoppingComponent.vue";
import AdminComponent from "./components/AdminComponent.vue";
import SpotifyStatsComponent from "./components/SpotifyStatsComponent.vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

const routes = [
  {
    path: "/",
    name: "Home",
    component: CounterComponent,
    meta: { title: "Skibidi" },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginComponent,
    meta: { title: "Skibidi - login" },
  },
  {
    path: "/shopping",
    name: "Shopping",
    component: ShoppingComponent,
    meta: {
      title: "Skibidi - shopping",
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: AdminComponent,
    meta: { title: "Skibidi - Admin", requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/spotify-stats",
    name: "Spotify Stats",
    component: SpotifyStatsComponent,
    meta: { title: "Spotify Statistics", requiresAuth: true },
  },
  {
    path: "/callback",
    name: "Callback",
    component: () => import("./components/CallbackComponent.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = getAuth();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);

  onAuthStateChanged(auth, async (user) => {
    if (requiresAuth && !user) {
      next({ name: "Login" });
    } else if (requiresAdmin) {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists() && userDoc.data().role === "admin") {
          next();
        } else {
          next({ name: "Home" });
        }
      } else {
        next({ name: "Login" });
      }
    } else {
      next();
    }
  });
});

export default router;
