<template>
  <div>
    <h1>{{ isRegistering ? "Register" : "Login" }}</h1>
    <div class="login" v-if="!isRegistering">
      <input v-model="email" type="email" placeholder="Email" />
      <input v-model="password" type="password" placeholder="Password" />
      <button @click="login">Login</button>
      <p>Don't have an account? <a @click="toggleForm">Register here</a></p>
    </div>
    <div class="register" v-else>
      <input v-model="email" type="email" placeholder="Email" />
      <input v-model="password" type="password" placeholder="Password" />
      <button @click="register">Register</button>
      <p>Already have an account? <a @click="toggleForm">Login here</a></p>
    </div>
  </div>
  <div>
    <h1>Login to Spotify</h1>
    <button @click="loginWithSpotify">Login with Spotify</button>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase"; 
import { doc, setDoc } from "firebase/firestore";
import { getSpotifyAuthorizeURL } from "../spotifyAuth";


export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const isRegistering = ref(false);
    const router = useRouter();

    const loginWithSpotify = () => {
      const spotifyUrl = getSpotifyAuthorizeURL();
      window.location.href = spotifyUrl; 
    };

    const login = async () => {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value);
        console.log("Logged in:", userCredential.user);
        router.push("/"); 
      } catch (error) {
        console.error("Login error:", error.message);
        alert("Login failed. Please check your credentials.");
      }
    };

    const register = async () => {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
        const user = userCredential.user;
        await setDoc(doc(db, "users", user.uid), {
          email: user.email,
          role: "user",
        });
        console.log("Registered:", user);
        router.push("/"); 
      } catch (error) {
        console.error("Registration error:", error.message);
        alert("Registration failed. Please try again.");
      }
    };

    const toggleForm = () => {
      isRegistering.value = !isRegistering.value;
    };

    return { email, password, isRegistering, login, register, toggleForm, loginWithSpotify };
  },
};
</script>

<style >
.login, .register {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

input {
  padding: 0.5rem;
  font-size: 1rem;
}

button {
  padding: 0.5rem 1rem;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

a {
  color: #4caf50;
  cursor: pointer;
}
</style>