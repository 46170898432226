import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBRiJC7ccRQ63aITTEXPsQS2QqHaqLLkwk",
  authDomain: "skibidi-a238d.firebaseapp.com",
  databaseURL:
    "https://skibidi-a238d-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "skibidi-a238d",
  storageBucket: "skibidi-a238d.firebasestorage.app",
  messagingSenderId: "415729452414",
  appId: "1:415729452414:web:8e7be14b30705ed0791f2f",
  measurementId: "G-B4CB1E3P9M",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Set persistent authentication
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error("Error setting persistence:", error.message);
});

export { auth, db };
