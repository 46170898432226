<template>
    <router-view :user="user" />
    <button v-if="user" @click="logout" type="button" class="logout">Logout</button>
</template>

<script>
import { ref, onMounted } from "vue";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./firebase";

export default {
  setup() {
    const user = ref(null);
    const accessToken = ref(null);


    const checkAuthState = () => {
      onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
          user.value = currentUser;
        } else {
          user.value = null;
        }
      });
    };

    const logout = async () => {
      try {
        await signOut(auth);
        user.value = null;
      } catch (error) {
        console.error("Error signing out:", error);
      }
    };

    const extractAccessToken = () => {
      const hash = window.location.hash;
      if (hash) {
        const params = new URLSearchParams(hash.substring(1)); // Odstraní #
        accessToken.value = params.get("access_token");
        window.history.pushState("", document.title, window.location.pathname); // Vymaže token z URL
      }
    };

    onMounted(() => {
      checkAuthState();
      extractAccessToken();

    });

    return {
      user,
      logout
    };
  }
};
</script>

<style scoped>

.logout {
  padding: 0.5rem;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

</style>