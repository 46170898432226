<template>
  <div>
    <button class="btn" @click="openModal">History</button>

    <div v-if="isModalOpen" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>Select Week</h2>

        <select v-model="selectedWeek" id="week-select" @change="filterData">
          <option value="" disabled selected>Select week</option>
          <option v-for="week in weeks" :key="week" :value="week">{{ week }}</option>
        </select>

        <label for="search">Search for user:</label>
        <input v-model="searchQuery" id="search" type="text" placeholder="Search..." />

        <div v-if="selectedWeek">
        <table v-if="filteredData.length">
          <thead>
            <tr>
              <th>Name</th>
              <th>Number</th>
              <th>Week</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredData" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.number }}</td>
              <td>{{ item.collection }}</td>
            </tr>
          </tbody>
        </table>

        <p v-else>No data found.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

export default {
  name: "HistoryComponent",
  data() {
    return {
      data: [], // Data z Firestore
      weeks: [], // Seznam týdnů
      filteredData: [], // Filtrovaná data pro zobrazení
      selectedWeek: "", // Vybraný týden
      searchQuery: "", // Vyhledávací dotaz
      isModalOpen: false, // Stav modálního okna
    };
  },
  methods: {
    async fetchCollections() {
      const db = getFirestore();
      const metaDoc = await getDoc(doc(db, "/counters/history")); // Dokument `history`
      if (metaDoc.exists()) {
        const metaData = metaDoc.data().meta;
        return Object.keys(metaData).filter((key) => metaData[key] === true);
      }
      return [];
    },
    async fetchData() {
      const db = getFirestore();
      const allData = [];

      // Získáme seznam kolekcí z dokumentu `meta`
      this.weeks = await this.fetchCollections();

      // Iterujeme přes dynamicky získané kolekce
      for (const col of this.weeks) {
        const querySnapshot = await getDocs(collection(db, `/counters/history/${col}`));
        querySnapshot.forEach((doc) => {
          allData.push({
            id: doc.id,
            ...doc.data(),
            collection: col, // Přidáme název kolekce
          });
        });
      }

      this.data = allData; // Uložíme všechna data
      this.filteredData = allData; // Základní filtrování (zobrazení všeho)
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    filterData() {
      // Filtrujeme data podle vybraného týdne
      this.filteredData = this.data.filter(
        (item) =>
          (!this.selectedWeek || item.collection === this.selectedWeek) &&
          (!this.searchQuery ||
            item.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
      );
    },
  },
  watch: {
    searchQuery() {
      this.filterData(); // Znovu aplikujeme filtr při změně vyhledávání
    },
  },
  async created() {
    await this.fetchData(); // Načteme data při vytvoření komponenty
  },
};
</script>

<style>
body:has(.modal)  {
  overflow: hidden;
}
</style>

<style scoped>



.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  backdrop-filter: blur(20px);
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.btn {
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

label {
  margin-top: 10px;
  display: block;
}

input, select {
  margin-top: 10px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}
</style>
