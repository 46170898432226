<template>
    <div class="wrapped-container" v-if="topTracks && topTracks.length">
      <div class="summary-section">
        <h1>Souhrnné Statistiky</h1>
        <ul>
          <li>Počet skladeb: {{ topTracks.length }}</li>
          <li>Průměrná délka: {{ formatDuration(averageDurationMs) }}</li>
          <li>Průměrná popularita: {{ averagePopularity.toFixed(1) }}</li>
          <li>Nejčastější interpret: {{ mostFrequentArtist }}</li>
          <li>Top 3 interpreti: {{ top3Artists.join(', ') }}</li>
          <li>Průměrné tempo: {{ averageTempo.toFixed(1) }} BPM</li>
          <li>Průměrná tanečnost (danceability): {{ averageDanceability.toFixed(2) }}</li>
          <li>Průměrná energie (energy): {{ averageEnergy.toFixed(2) }}</li>
          <li>Průměrná valence (nálada): {{ averageValence.toFixed(2) }}</li>
          <li>Nejdelší skladba: 
            <span v-if="longestTrack">{{ longestTrack.name }} ({{ formatDuration(longestTrack.duration_ms) }})</span>
          </li>
          <li>Nejkratší skladba: 
            <span v-if="shortestTrack">{{ shortestTrack.name }} ({{ formatDuration(shortestTrack.duration_ms) }})</span>
          </li>
          <li>Nejpomalejší skladba (tempo): 
            <span v-if="lowestTempoTrack && tempoTrackMap[lowestTempoTrack.id]">
              {{ tempoTrackMap[lowestTempoTrack.id].name }} ({{ lowestTempoTrack.tempo.toFixed(1) }} BPM)
            </span>
          </li>
          <li>Nejrychlejší skladba (tempo): 
            <span v-if="highestTempoTrack && tempoTrackMap[highestTempoTrack.id]">
              {{ tempoTrackMap[highestTempoTrack.id].name }} ({{ highestTempoTrack.tempo.toFixed(1) }} BPM)
            </span>
          </li>
          <li>Nejméně energická skladba:
            <span v-if="lowestEnergyTrack && tempoTrackMap[lowestEnergyTrack.id]">
              {{ tempoTrackMap[lowestEnergyTrack.id].name }} (energy: {{ lowestEnergyTrack.energy.toFixed(2) }})
            </span>
          </li>
          <li>Nejvíce energická skladba:
            <span v-if="highestEnergyTrack && tempoTrackMap[highestEnergyTrack.id]">
              {{ tempoTrackMap[highestEnergyTrack.id].name }} (energy: {{ highestEnergyTrack.energy.toFixed(2) }})
            </span>
          </li>
          <li>Nejstarší album:
            <span v-if="oldestAlbum">{{ oldestAlbum.album.name }} ({{ oldestAlbum.album.release_date }})</span>
          </li>
          <li>Nejnovější album:
            <span v-if="newestAlbum">{{ newestAlbum.album.name }} ({{ newestAlbum.album.release_date }})</span>
          </li>
          <li>Procento skladeb s vysokou energií (>0.7): {{ percentHighEnergy.toFixed(1) }}%</li>
        </ul>
      </div>
  
      <div class="tracks-list">
        <h2>Seznam Top Skladeb</h2>
        <ul>
          <li v-for="track in topTracks" :key="track.id" class="track-item">
            <img
              v-if="track.album.images && track.album.images.length"
              :src="track.album.images[0].url"
              alt="Album Art"
              class="album-cover"
            />
            <div class="track-info">
              <a :href="track.external_urls.spotify" target="_blank" class="track-name">
                {{ track.name }}
              </a>
              <div class="track-artists">
                {{ track.artists.map(a => a.name).join(', ') }}
              </div>
              <div class="track-details">
                Album: {{ track.album.name }} | Délka: {{ formatDuration(track.duration_ms) }} | Popularita: {{ track.popularity }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="no-tracks">
      <p>Žádné skladby k dispozici. Přihlaste se prosím do Spotify.</p>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, computed } from "vue";
  import axios from "axios";
  
  const topTracks = ref([]);
  const audioFeatures = ref([]);
  
  const fetchTopTracks = async () => {
    const accessToken = localStorage.getItem("spotifyAccessToken");
    if (!accessToken) {
      console.error("Přístupový token chybí. Přihlaste se do Spotify.");
      return;
    }
  
    try {
      const tracksResponse = await axios.get(
        "https://api.spotify.com/v1/me/top/tracks?limit=20&time_range=long_term",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
  
      topTracks.value = tracksResponse.data.items;
  
      if (topTracks.value.length > 0) {
        const trackIds = topTracks.value.map(t => t.id).join(',');
        const featuresResponse = await axios.get(
          `https://api.spotify.com/v1/audio-features?ids=${trackIds}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
  
        audioFeatures.value = featuresResponse.data.audio_features;
      }
    } catch (error) {
      console.error("Chyba při načítání top skladeb nebo audio features:", error);
    }
  };
  
  onMounted(() => {
    fetchTopTracks();
  });
  
  const formatDuration = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  
  const averageDurationMs = computed(() => {
    if (!topTracks.value.length) return 0;
    const total = topTracks.value.reduce((sum, t) => sum + t.duration_ms, 0);
    return total / topTracks.value.length;
  });
  
  const averagePopularity = computed(() => {
    if (!topTracks.value.length) return 0;
    const total = topTracks.value.reduce((sum, t) => sum + t.popularity, 0);
    return total / topTracks.value.length;
  });
  
  const mostFrequentArtist = computed(() => {
    if (!topTracks.value.length) return '';
    const artistCount = {};
    topTracks.value.forEach(track => {
      track.artists.forEach(artist => {
        artistCount[artist.name] = (artistCount[artist.name] || 0) + 1;
      });
    });
    let maxCount = 0;
    let frequentArtist = '';
    for (const [artist, count] of Object.entries(artistCount)) {
      if (count > maxCount) {
        maxCount = count;
        frequentArtist = artist;
      }
    }
    return frequentArtist;
  });
  
  // Top 3 interpreti
  const top3Artists = computed(() => {
    if (!topTracks.value.length) return [];
    const artistCount = {};
    topTracks.value.forEach(track => {
      track.artists.forEach(artist => {
        artistCount[artist.name] = (artistCount[artist.name] || 0) + 1;
      });
    });
    const sorted = Object.entries(artistCount).sort((a,b) => b[1] - a[1]);
    return sorted.slice(0,3).map(item => item[0]);
  });
  
  const averageOfFeature = (featureName) => {
    if (!audioFeatures.value || !audioFeatures.value.length) return 0;
    const validFeatures = audioFeatures.value.filter(f => f && f[featureName] !== null);
    if (!validFeatures.length) return 0;
    const total = validFeatures.reduce((sum, f) => sum + f[featureName], 0);
    return total / validFeatures.length;
  };
  
  const averageTempo = computed(() => averageOfFeature('tempo'));
  const averageDanceability = computed(() => averageOfFeature('danceability'));
  const averageEnergy = computed(() => averageOfFeature('energy'));
  const averageValence = computed(() => averageOfFeature('valence'));
  
  // Nejdelší a nejkratší skladba
  const longestTrack = computed(() => {
    if (!topTracks.value.length) return null;
    return topTracks.value.reduce((max, t) => (t.duration_ms > max.duration_ms ? t : max), topTracks.value[0]);
  });
  const shortestTrack = computed(() => {
    if (!topTracks.value.length) return null;
    return topTracks.value.reduce((min, t) => (t.duration_ms < min.duration_ms ? t : min), topTracks.value[0]);
  });
  
  // Mapování ID audio features na původní tracky pro identifikaci skladeb
  const tempoTrackMap = computed(() => {
    const map = {};
    if (!audioFeatures.value.length) return map;
    audioFeatures.value.forEach(f => {
      if (f && f.id) {
        // Najdeme track podle ID
        const track = topTracks.value.find(t => t.id === f.id);
        if (track) map[f.id] = track;
      }
    });
    return map;
  });
  
  // Nejpomalejší a nejrychlejší skladba (dle tempa)
  const highestTempoTrack = computed(() => {
    if (!audioFeatures.value.length) return null;
    return audioFeatures.value.reduce((max, f) => (f && f.tempo > max.tempo ? f : max), audioFeatures.value[0]);
  });
  const lowestTempoTrack = computed(() => {
    if (!audioFeatures.value.length) return null;
    return audioFeatures.value.reduce((min, f) => (f && f.tempo < min.tempo ? f : min), audioFeatures.value[0]);
  });
  
  // Nejméně a nejvíce energická skladba
  const highestEnergyTrack = computed(() => {
    if (!audioFeatures.value.length) return null;
    return audioFeatures.value.reduce((max, f) => (f && f.energy > max.energy ? f : max), audioFeatures.value[0]);
  });
  const lowestEnergyTrack = computed(() => {
    if (!audioFeatures.value.length) return null;
    return audioFeatures.value.reduce((min, f) => (f && f.energy < min.energy ? f : min), audioFeatures.value[0]);
  
  });
  
  // Nejstarší a nejnovější album (dle release_date)
  const oldestAlbum = computed(() => {
    if (!topTracks.value.length) return null;
    return topTracks.value.reduce((oldest, t) => {
      if (!oldest) return t;
      const currentDate = new Date(t.album.release_date);
      const oldestDate = new Date(oldest.album.release_date);
      return currentDate < oldestDate ? t : oldest;
    }, null);
  });
  
  const newestAlbum = computed(() => {
    if (!topTracks.value.length) return null;
    return topTracks.value.reduce((newest, t) => {
      if (!newest) return t;
      const currentDate = new Date(t.album.release_date);
      const newestDate = new Date(newest.album.release_date);
      return currentDate > newestDate ? t : newest;
    }, null);
  });
  
  // Procento skladeb s vysokou energií (>0.7)
  const percentHighEnergy = computed(() => {
    if (!audioFeatures.value.length) return 0;
    const threshold = 0.7;
    const count = audioFeatures.value.filter(f => f && f.energy >= threshold).length;
    return (count / audioFeatures.value.length) * 100;
  });
  
  </script>
  
  <style scoped>
  .wrapped-container {
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #fff;
    padding: 20px;
  }
  
  .wrapped-container h1 {
    text-align: center;
    font-size: 2em;
    margin-bottom: 30px;
  }
  
  .summary-section {
    background: rgba(255, 255, 255, 0.05); 
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 40px;
    color: #fff;
    backdrop-filter: blur(20px);
  }
  
  .summary-section h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .summary-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .summary-section li {
    margin-bottom: 8px;
    font-size: 0.95em;
    color: #ccc;
  }
  
  .tracks-list h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
  }
  
  .tracks-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .track-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
    background: rgba(255, 255, 255, 0.05);
    padding: 10px;
    border-radius: 6px;
    backdrop-filter: blur(20px);
  }
  
  .album-cover {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 15px;
    border-radius: 4px;
  }
  
  .track-info {
    display: flex;
    flex-direction: column;
  }
  
  .track-name {
    font-weight: bold;
    color: #1DB954; 
    text-decoration: none;
    font-size: 1.1em;
    margin-bottom: 5px;
  }
  
  .track-name:hover {
    text-decoration: underline;
  }
  
  .track-artists {
    font-size: 0.9em;
    color: #bbb;
    margin-bottom: 5px;
  }
  
  .track-details {
    font-size: 0.85em;
    color: #aaa;
  }
  
  .no-tracks {
    text-align: center;
    margin-top: 50px;
    font-size: 1.2em;
    color: #fff;
    opacity: 0.8;
  }
  </style>
  